
export const useApp = () => {
  const { $isSol } = useNuxtApp()

  const appMeta = computed(
    () => $isSol
      ? ({
        title: 'Solresor appen',
        playAppId: 'solresor.se',
        appStoreAppPath: 'solresor/id6503487454',
      })
      : ({
        title: 'Rolfsbuss appen',
        playAppId: 'se.rolfsbuss',
        appStoreAppPath: 'rolfsbuss/id6547860913',
      })
  )

  const playAppUri = computed(() => `https://play.google.com/store/apps/details?id=${appMeta.value.playAppId}`)
  const appStoreAppUri = computed(() => `https://apps.apple.com/app/${appMeta.value.appStoreAppPath}`)

  const redirectToAppStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
      window.location.href = playAppUri.value
    } else if (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) && !window.MSStream) {
      window.location.href = appStoreAppUri.value
    }
  }

  return {
    appMeta,
    redirectToAppStore,
    playAppUri,
    appStoreAppUri,
  }
}